import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  concat,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client'
import { gql } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { RetryLink } from '@apollo/client/link/retry'
import { getMainDefinition } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'
import { extractFiles } from 'extract-files'

// fragment matcher hints for interface/union types
// ref: https://www.apollographql.com/docs/react/migrating/apollo-client-3-migration/#cache-improvements
// ref: https://graphql-code-generator.com/docs/plugins/fragment-matcher
// import interfacesIntrospection from "@plco-pro/graphqls/react.generated";
import { __CLIENT_DEBUG__, __ENV__ } from '@plco-pro/env'
import { typePolicies } from '@plco-pro/graphqls/config'
import { responseObjects } from '@plco-pro/graphqls/responseObjects'
import {
  CUSTOM_TIMEOUT_MESSAGE,
  customTimeout,
  isCustomTimeoutOperation,
} from '@plco-pro/graphqls/utils'

// const possibleTypes = interfacesIntrospection.__schema.types.reduce(
//   (map, type) => {
//     map[type.name] = type.possibleTypes.map(t => t.name);
//     return map;
//   },
//   {} as PossibleTypesMap,
// );

const defaultApolloLink: ApolloLink = new ApolloLink((operation, forward) => {
  return forward(operation)
})

const defaultConnectionParams = async () => ({
  'X-Test-GraphQL-Context': new Date().toString(),
})

const versionIgnoreKeyParams = () => {
  return { 'X-Ignore-Version-Check': process.env.VERSION_IGNORE_KEY }
}

export type CreateApolloClientOptions = {
  httpURI: string
  errorLink?: ApolloLink
  authLink?: ApolloLink
  loggerLink?: ApolloLink
  getContext?: () => Promise<{ [key: string]: string } | undefined> // used for connectionParams and header
}
export function createApolloClient({
  httpURI,
  errorLink = defaultApolloLink,
  authLink = defaultApolloLink,
  loggerLink = defaultApolloLink,
  getContext = defaultConnectionParams,
}: CreateApolloClientOptions) {
  // auto retrying on network error
  const retryLink = new RetryLink({
    attempts: {
      max: 3,
      retryIf: (_, { operationName }) => !isCustomTimeoutOperation(operationName),
    },
  })

  // http request interface (with batching)
  const httpLink = new HttpLink({
    uri: httpURI,
    credentials: 'omit',
    fetch(url, options) {
      return new Promise((res, rej) => {
        const abortHandler = () => {
          if (
            typeof options?.headers === 'object' &&
            'logger-timeout' in options.headers &&
            typeof options.headers['logger-timeout'] === 'number'
          ) {
            clearTimeout(options.headers['logger-timeout'])
          }
        }

        const addAbortListener = () => {
          options?.signal?.addEventListener('abort', abortHandler)
        }

        const clearAbortListener = () => {
          options?.signal?.removeEventListener('abort', abortHandler)
        }

        if (options) {
          addAbortListener()
          customTimeout(options, () => {
            clearAbortListener()
            rej(
              new ApolloError({
                clientErrors: [{ name: 'timeout', message: CUSTOM_TIMEOUT_MESSAGE }],
              }),
            )
          })
        }

        return fetch(url, options).then(res).catch(rej).finally(clearAbortListener)
      })
    },
  })

  // http multipart/form-data (file uploader) request interface
  const uploadLink = createUploadLink({
    uri: httpURI,
    credentials: 'omit',
  })

  // create fresh cache
  const cache = new InMemoryCache({
    resultCaching: true,
    addTypename: true,
    // possibleTypes,
    typePolicies,
    dataIdFromObject(responseObject) {
      return responseObjects(responseObject)
    },
    resultCacheMaxSize: Math.pow(2, 30),
  })

  // http (multipart/formdata or x-www-form-urlencoded) link
  const httpLinks = concat(
    // set headers (middleware)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setContext(async (_, { headers }) => {
      return {
        headers: {
          ...headers,
          ...versionIgnoreKeyParams(),
          ...(await getContext()),
        },
      }
    }),

    split(
      (op) => {
        const definition = getMainDefinition(op.query)
        return (
          (definition.kind === 'OperationDefinition' &&
            definition.operation === 'mutation' &&
            extractFiles(op).files.size > 0) ||
          op.getContext().omitBatch
        )
      },
      // multipart request
      uploadLink, // terminating without retrying

      // http request
      concat(
        retryLink, // afterware
        httpLink, // terminating
      ),
    ),
  )

  // ws or http link
  const httpAndWsLinks = split(
    (op) => {
      const definition = getMainDefinition(op.query)
      return definition.kind === 'OperationDefinition'
    },

    httpLinks,
  )

  // create final apollo client
  const client = new ApolloClient({
    link: ApolloLink.from([loggerLink, errorLink, authLink, httpAndWsLinks]),
    cache,
    ssrMode: false,
    queryDeduplication: true,
    connectToDevTools: __ENV__ === 'development',
  })

  // for debug
  if (__CLIENT_DEBUG__) {
    const w = (window.top || window) as any
    w.apollo = client
    w.gql = gql
  }

  return client
}
