export const plan = {
  en: {
    // plan step
    'plan.step': 'Step {step} of {total}',

    // plan common
    'plan.team.amount': '₩{amount} / Month',
    'plan.contact-sales': 'Contact sales',
    'plan.next': 'Next',
    'plan.contact': 'Contact',
    'plan.make-payment': 'Make payment',
    'plan.plan-info': 'Plan info',
    'plan.plan-info.discount-count':
      '1st~{limitCount, plural, =1 {#st} =2 {#nd} =3 {#rd} other {#th}} payment',
    'plan.plan-info.discount-count.first': '1st payment',
    'plan.plan-info.original-price-count': '{limitCount} payment~',
    'plan.plan': 'Plan',
    'plan.current.plan': 'Current Plan',
    'plan.new.plan': 'New Plan',
    'plan.team': 'Team',
    'plan.elite': 'Elite',
    'plan.pro': 'Pro',
    'plan.payment.method': 'Payment method',
    'plan.register-card': 'Register card',
    'plan.change-card.info.lgAndUp':
      '· ₩100 will be paid for validation and refunded within 10 minutes.',
    'plan.change-card.info.mdOnly':
      '· ₩100 will be paid for validation and refunded within 10 minutes.',
    'plan.change-card.info.smOnly':
      '· ₩100 will be paid for validation and \n refunded within 10 minutes.',
    'plan.change-card.error.info': 'Please register your payment card.',
    'plan.change.card': 'Change card',
    'plan.players-number': 'Number Of Players',
    'plan.payment-date': 'Payment Date',
    'plan.next.payment-date': 'Next payment date',
    'plan.invoice.details': 'Invoice details',
    'plan.buyer.name': 'Name',
    'plan.buyer.email': 'Email',
    'plan.buyer.phone': 'Phone',
    'plan.discount.details': 'Discount details',
    'plan.discount.coupon.null': 'There are no coupons applicable.',
    'plan.discount.coupon.none': "Don't apply coupon",
    'plan.payment.amount': 'Payment amount',
    'plan.current.payment.amount': 'Current plan amount',
    'plan.new.payment.amount': 'New plan payment amount',
    'plan.subtotal': 'Subtotal',
    'plan.discount': 'Discount',
    'plan.invoice.id': 'Invoice ID',
    'plan.payment.date': 'Payment date',
    'plan.total': 'Total',
    'plan.total.amount': 'Total',
    'plan.regular.payment.amount': 'Regular Payment amount',
    'plan.regular.payment.date': 'Payment date',
    'plan.status': 'Status',
    'plan.view': 'View',
    'plan.download': 'Download',
    'plan.players': '{players}',
    'plan.teams': '{teams} teams',
    'plan.amount.unit': '₩{amount}',
    'plan.remaining.days': 'Remaining days',
    'plan.expected.refund.amount': 'Expected refund amount',
    'plan.day': '{day, plural, =1 {{day}day} other {{day}days}}',
    'plan.coupon': 'Coupon',

    // plan error
    'plan.card-error':
      'An error occurred due to a card error. Please change the card and try again.',
    'plan.server-error': 'Payment failed due to server error. Please try payment again.',
    'plan.incomplete-error': 'Your card registration has not been completed. Please try again.',

    // plan
    price__section1__title: 'Please select a plan.',
    price__section1__description:
      "You can choose a customized plan\nfor your team's characteristics and environment.",

    price__card__contact__title: 'Inquiries about plan',
    price__card__contact__description:
      'Custom Plan Consultation\n(associations, federations, offices of education, etc.)',

    price__card__toggle__annual: '20% off annual subscription',

    price__card__lite__title: 'Lite',
    price__card__lite__description: 'Essential basic tasks at a\nreasonable price',
    price__card__lite__payment_month: '₩55,000 / month',
    price__card__lite__payment_month_og: '₩495,000',
    price__card__lite__payment__year: '₩528,000 / year',
    price__card__lite__payment__year__og__1: '₩5,940,000',
    price__card__lite__payment__year__og__2: '₩660,000',
    price__card__lite__feature_1: 'Manage team schedules',
    price__card__lite__feature_2: 'Training/match report management',
    price__card__lite__feature_3: 'Team Data Monitoring',
    price__card__lite__feature_4: 'Player Data Monitoring',
    price__card__lite__feature_5: 'Communication',

    price__card__elite__title: 'Elite',
    price__card__elite__description: 'Maximize convenience with\nautomation service',
    price__card__elite__payment__month: '₩77,000 / month',
    price__card__elite__payment__month__og: '₩550,000',
    price__card__elite__payment__year: '₩739,200 / year',
    price__card__elite__payment__year__og__1: '₩6,600,000',
    price__card__elite__payment__year__og__2: '₩924,000',
    price__card__elite__feature: 'All features and services of the light plan are included.',
    price__card__elite__feature_1: 'Automation service for sending unchecked notifications',
    price__card__elite__feature_2: 'Daily/weekly report KakaoTalk service',
    price__card__elite__feature_3: 'Unchecked Player List Kakao Talk Service',

    price__card__pro__title: 'Pro',
    price__card__pro__label: 'for Professional',
    price__card__pro__description: 'All features and even\nprofessional features tailored to you',
    price__card__pro__payment__month: '₩550,000 / month',
    price__card__pro__payment__month__og: '₩1,100,000',
    price__card__pro__payment__year: '₩5,280,000 / year',
    price__card__pro__payment__year__og__1: '₩13,200,000',
    price__card__pro__payment__year__og__2: '₩6,600,000',
    price__card__pro__feature:
      'All the features and services of the Lite, Elite plan are included.',
    price__card__pro__feature_1: 'Data Source Extraction Service (once a month)',
    price__card__pro__feature_2: 'Data Report Service (once a month)',

    price__card__promotion__banner: 'Only for students and amateur teams',
    price__card__pro__banner: 'Only for Pro Teams',
    price__card__promotion_notice:
      'As a promotion plan for students or amateurs\nwe can also ask for proof.',
    price__card__select_button: 'Select',

    'plan.elite.description': 'Plans for elementary to\ncollege teams.',
    'plan.pro.description':
      'Plan for professional level such as professionals, national representatives, etc.',

    'plan.pay.contect-description':
      'If you need to discuss team count, usage period, payment method other than monthly payments, etc., please inquire. (e.g. associations, federations, education offices, etc.)',
    'plan.pay.contact-link':
      'https://docs.google.com/forms/d/e/1FAIpQLScusDPysqIAKPXHgaRDOCifXU3Qxf2tSmoyWSqjP3x_ZRl0Bw/viewform',

    // plan plan-option
    'plan.option.title': 'Select the number of players',
    'plan.option.players-number': '{total} players',
    'plan.option.subtitle.smOnly':
      'There are {players} in the team. Owner and admin \n can be added without any additional limit on the \n number of people.',
    'plan.option.subtitle.mdAndUp':
      'There are {players} in the team. Owner and admin can be added \n without any additional limit on the number of people.',
    'plan.option.info-part1': 'If you want a lower plan, please go to the ',
    'plan.option.info-part2.lgAndUp': ', change the number of players, and try again.',
    'plan.option.info-part2.mdAndDown': ',',
    'plan.option.info-part3.mdAndDown': 'change the number of players, and try again.',
    'plan.option.10': '1~10',
    'plan.option.20': '11~20',
    'plan.option.40': '21~40',
    'plan.option.50': '41~50',
    'plan.option.100': '51~100',
    'plan.option.100+': '101~',
    'plan.option.disabled-card-info':
      'This option cannot be selected because the number of players on the team has been exceeded.',
    'plan.option.current.plan': 'Current Plan',
    'plan.option.select.plan.error': 'Please select the desired plan.',

    // plan pricing
    'plan.pricing.title.smOnly': 'Check the plan and payment \n amount',
    'plan.pricing.title.mdAndUp': 'Check the plan and payment amount',
    'plan.pricing.subtitle.smOnly':
      'Payment will be processed after \nconfirmation of payment information.',
    'plan.pricing.subtitle.mdAndUp':
      'Payment will be processed after confirmation of payment information.',
    'plan.pricing.payment-day': 'The {day} of every month.',
    'plan.pricing.total': 'Total',
    'plan.pricing.amount.info.smOnly':
      'After you start using the paid service, \n you cannot withdraw your subscription.',
    'plan.pricing.amount.info.mdAndUp':
      'After you start using the paid service, you cannot withdraw your subscription.',
    'plan.pricing.terms': 'Save your payment information and agree to a monthly subscription.',
    'plan.pricing.current-plan.info': 'You cannot select the same plan as your current plan.',
    'plan.pricing.plan-info.notice':
      'Regular payment will be made with the changed amount from {day}',
    'plan.change-plan.notice': 'Change Plan Notice',
    'plan.change-plan.notice.content': `· Even after changing the card, the refund will be made to the 
    \u00A0 plan payment card before the change.
    · Cancellation amount calculation method: 
    \u00A0 {(Current plan payment amount) / 30 days } X remaining days
    · Additional Payment amount calculation method: 
    \u00A0 {(New plan payment amount) / 30 days } X remaining days
    · Remaining days: Change date ~ next payment date
    · Refund processing can take at least 2 to 5 business days 
    \u00A0 depending on the credit card company.
    · If you change the plan within 24 hours of payment, the new
    \u00A0 plan will be paid after the full refund  of the current plan 
    \u00A0 amount.`,
    'plan.change-plan.amount.notice':
      'If you change the plan within 24 hours of payment, the new plan will be paid after the full refund \n of the current plan amount.',
    'plan.change-plan.impossible':
      'If the next payment date is less than 24 hours away, you will not be able to change your plan.',

    'plan.coupon.notice': 'Apply coupon Notice',
    'plan.coupon.notice.content': `· When you cancel the plan in the middle, the coupon benefits will disappear.
    · You can not apply coupon duplicately.
    · The same coupon can be used once per team.
    · In the case of a plan with a coupon applied, the plan option (maximum number of people) cannot be changed. If you need to change your options, you must resume your plan after the end of the plan and the coupon benefits will expire.`,

    // plan processing
    'plan.processing.title': 'Payment is being processed.',
    'plan.processing.subtitle': 'Card payment is in progress. Please wait.',

    // plan completed
    'plan.completed.title.smOnly': 'The payment \n has been completed',
    'plan.completed.title.mdAndUp': 'The payment has been completed!',
    'plan.completed.subtitle.part1.lgAndUp':
      'You can check the plan information and payment details on',
    'plan.completed.subtitle.part1.mdAndDown': 'You can check the plan information and',
    'plan.completed.subtitle.part2': '\u00A0',
    'plan.completed.subtitle.part3': 'payment details on',
    'plan.completed.go-home': 'Go Home',

    // plan & billing
    'plan.billing.title': 'Plan & Billing',
    'plan.billing.plan-details': '🧢 Plan Details',
    'plan.billing.players-number': 'Current number of players',
    'plan.billing.players-number.can-add': 'The number of players you can add',
    'plan.billing.plan-expired': 'Plan expired',
    'plan.billing.cancel.plan': 'Cancel plan',
    'plan.billing.refund.plan': 'Refund plan',
    'plan.billing.change.plan': 'Change plan',
    'plan.billing.subscribe.plan': 'Subscribe plan',
    'plan.billing.resume.plan': 'Resume plan',
    'plan.billing.retry.payment': 'Retry payment',
    'plan.billing.cancel': 'Cancel plan',
    'plan.billing.refund': 'Refund',
    'plan.billing.payment.details': '💳 Payment Details',
    'plan.billing.payment.date.expires': 'Expires on {date}',
    'plan.billing.invoice.details': '📩 Invoice Details',
    'plan.billing.edit': 'Edit',
    'plan.billing.payment.history': '🧾 Payment history',
    'plan.billing.license.period': 'License Period',
    'plan.billing.connected.teams': 'Connected teams',
    'plan.billing.license.name': 'License Name',
    'plan.billing.license.owner': 'License Owner',
    'plan.billing.available.team': 'Available team seats',

    'plan.billing.payment-fail.help.text':
      'Payment has failed. \n' +
      'Payment retries will be attempted a total of four times at 12:00 PM and 12:00 AM. ' +
      'If payment is not completed during the four retries, the subscription will expire. Please check or update your card information before the subscription expires.',

    // plan & billing status
    'plan.billing.status.refund': 'Refund',
    'plan.billing.status.pay': 'Paid',
    'plan.billing.status.fail': 'Declined',
    'plan.billing.status.loading': 'Payment processing',
    'plan.billing.status.success': 'Payment successful',
    'plan.billing.status.failed': 'Payment failed',

    // edit invoice
    'plan.edit.invoice.title': 'Edit Invoice Details',

    // change card
    'plan.change.card.title': 'Are you sure to change the card?',
    'plan.change.card.subtitle.mdAndUp':
      'When changing the card, 100 won is charged for \n card validation and refunded within 10 minutes.',
    'plan.change.card.subtitle.smOnly':
      'When changing the card, 100 won is charged for \n card validation and refunded within 10 minutes.',
    'plan.change.card.button': 'Change',
    'plan.change.card.product.name': 'Card validation check',

    // refund plan
    'plan.refund-plan.title': 'Are you sure you want to \n refund the plan?',
    'plan.refund-plan.subtitle': 'You can refund the plan within 24 hours \n after payment.',
    'plan.refund-plan.toasts.success': 'The plan has been successfully refunded.',

    // retry payment
    'plan.retry-payment.title': 'Are you sure you want to retry payment?',
    'plan.retry-payment.subtitle':
      'If you want to change your card, please change the card in Payment details> Payment method and try again.',
    'plan.retry-payment.toasts.success': 'Payment has been successfully completed.',
    'plan.retry-payment.toasts.fail': 'Payment has failed. Please check your payment method.',

    // resume plan
    'plan.resume-plan.title': 'Are you sure you want to \n resume the plan?',
    'plan.resume-plan.button': 'Resume',
    'plan.resume-plan.toasts.success': 'The plan has been resumeed.',

    // cancel plan
    'plan.cancel-plan.title': 'Are you sure you want to \n cancel your plan?',
    'plan.cancel-plan.subtitle':
      'The plan will be terminated \n on {day}. When you resume the plan, you can monitor it with stored data.',
    'plan.cancel-plan.toasts.success': 'Your plan has been successfully canceled.',

    // change applied coupon
    'plan.change.applied-coupon.title': 'Plan changes are not possible when applying a coupon.',
    'plan.change.applied-coupon.subtitle':
      'If you want to change the number of plan \n users, please cancel your current plan and try again.',

    // plan expiration
    'plan.expiration.title': 'The plan has expired.',
    'plan.free-trial.expiration.title': 'The free trial period has expired.',
    'plan.expiration.subtitle.smOnly':
      "Subscribe to Plan and continue to use \n PLCO's various features. 😊",
    'plan.expiration.subtitle.mdAndUp':
      "Subscribe to Plan and continue to use PLCO's various features. 😊",
  },
  ko: {
    // plan.step
    'plan.step': '{step}/{total} 단계',

    // plan common
    'plan.team.amount': '{amount}원 / 월',
    'plan.contact-sales': '문의 요망',
    'plan.next': '다음',
    'plan.contact': '문의하기',
    'plan.make-payment': '결제',
    'plan.plan-info': '플랜 정보',
    'plan.plan-info.discount-count.first': '1회차',
    'plan.plan-info.discount-count': '1~{limitCount}회차',
    'plan.plan-info.original-price-count': '{limitCount}회차 부터 ~',
    'plan.plan': '플랜',
    'plan.current.plan': '현재 플랜',
    'plan.new.plan': '새로운 플랜',
    'plan.team': '팀',
    'plan.elite': '엘리트',
    'plan.pro': '프로',
    'plan.payment.method': '결제 수단',
    'plan.register-card': '카드 등록',
    'plan.change-card.info.lgAndUp':
      '· 카드 등록시 유효성 검사를 위해 100원이 결제되며, 10분 이내로 환불 됩니다.',
    'plan.change-card.info.mdOnly':
      '· 카드 등록시 유효성 검사를 위해 100원이 결제되며, \n 10분 이내로 환불 됩니다.',
    'plan.change-card.info.smOnly':
      '· 카드 등록시 유효성 검사를 위해 100원이 \n 결제되며, 10분 이내로 환불 됩니다.',
    'plan.change-card.error.info': '결제 수단을 등록해 주세요.',
    'plan.change.card': '카드 변경',
    'plan.players-number': '선수 인원',
    'plan.payment-date': '정기결제일',
    'plan.next.payment-date': '다음 결제일',
    'hyper.next.payment.date': '다음 정기 결제일',
    'plan.invoice.details': '인보이스 정보',
    'plan.buyer.name': '이름',
    'plan.buyer.email': '이메일',
    'plan.buyer.phone': '휴대전화',
    'plan.discount.details': '할인 정보',
    'plan.discount.coupon.null': '적용 가능한 쿠폰이 없습니다.',
    'plan.discount.coupon.none': '쿠폰 적용 안함',
    'plan.payment.amount': '결제 금액',
    'plan.current.payment.amount': '현재 플랜 금액',
    'plan.new.payment.amount': '새로운 플랜 금액',
    'plan.subtotal': '플랜 금액',
    'plan.discount': '할인 금액',
    'plan.invoice.id': '결제 번호',
    'plan.payment.date': '결제일',
    'plan.total': '결제 금액',
    'plan.total.amount': '총 금액',
    'plan.regular.payment.amount': '정기결제금액',
    'plan.hyper.regular.payment.date': '정기결제일',
    'plan.regular.payment.date': '정기 결제일',
    'plan.status': '상태',
    'plan.view': '보기',
    'plan.download': '다운로드',
    'plan.players': '{players}명',
    'plan.teams': '{teams}팀',
    'plan.amount.unit': '{amount}원',
    'plan.remaining.days': '남은 일수',
    'plan.expected.refund.amount': '환불 예정 금액',
    'plan.day': '{day}일',
    'plan.coupon': '쿠폰',
    'plan.hyper': '플코 하이퍼',

    // plan error
    'plan.card-error':
      '카드 오류로 결제 실패 되었습니다. 카드를 변경하여 결제를 다시 시도해 주세요.',
    'plan.server-error': '서버 에러로 결제 실패 되었습니다. 결제를 다시 시도해 주세요.',
    'plan.incomplete-error': '카드 등록이 완료되지 않았습니다. 다시 시도해주세요.',

    // plan
    price__section1__title: '플랜을 선택해 주세요',
    price__section1__description: '팀의 특성과 환경에 필요한 맞춤형 플랜을 선택할 수 있습니다.',

    price__card__contact__title: '플랜 문의하기',
    price__card__contact__description: '맞춤형 플랜 상담\n(협회, 연맹, 교육청 등)',

    price__card__toggle__annual: '연 구독하고 20% 할인받기',

    price__card__lite__title: '라이트',
    price__card__lite__description: '필수 기본 업무를\n합리적인 가격으로',
    price__card__lite__payment_month: '55,000원 / 월',
    price__card__lite__payment_month_og: '495,000원',
    price__card__lite__payment__year: '528,000원 / 연',
    price__card__lite__payment__year__og__1: '5,940,000원',
    price__card__lite__payment__year__og__2: '660,000원',
    price__card__lite__feature_1: '팀 일정 관리',
    price__card__lite__feature_2: '훈련/경기 리포트 관리',
    price__card__lite__feature_3: '팀 데이터 모니터링',
    price__card__lite__feature_4: '선수 데이터 모니터링',
    price__card__lite__feature_5: '커뮤니케이션',

    price__card__elite__title: '엘리트',
    price__card__elite__description: '자동화 서비스까지 더해\n극대화되는 편의성',
    price__card__elite__payment__month: '77,000원 / 월',
    price__card__elite__payment__month__og: '550,000원',
    price__card__elite__payment__year: '739,200원 / 연',
    price__card__elite__payment__year__og__1: '6,600,000원',
    price__card__elite__payment__year__og__2: '924,000원',
    price__card__elite__feature: '라이트 플랜의 모든 기능·서비스 포함',
    price__card__elite__feature_1: '미체크 알림 발송 자동화 서비스',
    price__card__elite__feature_2: '일간/주간 리포트 카카오톡 서비스',
    price__card__elite__feature_3: '미체크 선수 리스트 카카오톡 서비스',

    price__card__pro__title: '프로',
    price__card__pro__label: 'for Professional',
    price__card__pro__description: '모든 기능에\n맞춤형 전문 기능까지',
    price__card__pro__payment__month: '550,000원 / 월',
    price__card__pro__payment__month__og: '1,100,000원',
    price__card__pro__payment__year: '5,280,000원 / 연',
    price__card__pro__payment__year__og__1: '13,200,000원',
    price__card__pro__payment__year__og__2: '6,600,000원',
    price__card__pro__feature: '라이트, 엘리트 플랜의 모든 기능·서비스 포함',
    price__card__pro__feature_1: '데이터 원본 추출 서비스 (월 1회)',
    price__card__pro__feature_2: '데이터 리포트 서비스 (월 1회)',

    price__card__promotion__banner: '학생 · 아마추어팀 전용',
    price__card__pro__banner: '프로팀 전용',
    price__card__promotion_notice:
      '학생 및 아마추어팀, 또는 트레이닝센터 등을 위한\n프로모션 플랜으로, 증빙을 요청드릴 수도 있습니다.',
    price__card__select_button: '선택',

    'plan.elite.description': '초, 중, 고, 대학 등\n학생팀을 위한 플랜',
    'plan.pro.description': '프로, 국가대표, 실업팀 등\n프로 레벨을 위한 플랜',

    'plan.pay.contect-description':
      '월 정기 결제가 아닌, 팀 수/사용 기간/결제 수단 등 협의가 필요한 경우 문의해 주세요. (예: 협회, 연맹, 교육청 등)',
    'plan.pay.contact-link':
      'https://docs.google.com/forms/d/e/1FAIpQLSc_xCpynM6NSlA291tEi4f7EAtoPUc5_s5wfmpd0xCs8xMPqA/viewform',

    // plan plan-option
    'plan.option.title': '선수 인원을 선택해 주세요',
    'plan.option.players-number': '선수 인원 {total}명',
    'plan.option.subtitle.smOnly':
      '현재 팀 내 {players}입니다. 소유자와 관리자 권한은 \n 별도의 인원 제한 없이 추가 가능합니다.',
    'plan.option.subtitle.mdAndUp':
      '현재 팀 내 {players}입니다. \n 소유자와 관리자 권한은 별도의 인원 제한 없이 추가 가능합니다.',
    'plan.option.info-part1': '더 낮은 플랜을 원하시면',
    'plan.option.info-part2.lgAndUp': '으로 이동하여 선수 인원 변경후 선택해주세요.',
    'plan.option.info-part2.mdAndDown': '으로 이동하여',
    'plan.option.info-part3.mdAndDown': '선수 인원 변경후 선택해주세요.',
    'plan.option.10': '1~10인',
    'plan.option.20': '11~20인',
    'plan.option.40': '21~40인',
    'plan.option.50': '41~50인',
    'plan.option.100': '51~100인',
    'plan.option.100+': '101인이상',
    'plan.option.disabled-card-info': '팀 내 선수 인원이 초과되어 선택할 수 없습니다.',
    'plan.option.current.plan': '현재 이용중인 플랜',
    'plan.option.select.plan.error': '원하시는 플랜을 선택하세요.',

    // plan pricing
    'plan.pricing.title.smOnly': '플랜 및 결제 금액을 확인해 주세요',
    'plan.pricing.title.mdAndUp': '플랜 및 결제 금액을 확인해 주세요',
    'plan.pricing.subtitle.smOnly': '결제 정보 확인후 결제가 진행됩니다.',
    'plan.pricing.subtitle.mdAndUp': '결제 정보 확인후 결제가 진행됩니다.',
    'plan.pricing.payment-day': '매월 {day}일',
    'hyper.pricing.payment-day': '매월 {day}일',
    'plan.pricing.total': '최종 금액',
    'plan.pricing.amount.info.smOnly':
      '유료서비스의 이용을 개시한 이후로는 \n 청약철회가 불가합니다.',
    'plan.pricing.amount.info.mdAndUp':
      '유료서비스의 이용을 개시한 이후로는 청약철회가 불가합니다.',
    'plan.pricing.terms': '결제 정보를 저장하고 월 정기 결제를 동의합니다.',
    'plan.pricing.current-plan.info': '현재 플랜과 동일한 플랜은 선택할 수 없습니다.',
    'plan.pricing.plan-info.notice': '{day} 부터 변경된 금액으로 정기결제가 진행됩니다.',
    'plan.change-plan.notice': '플랜 변경 유의사항',
    'plan.change-plan.notice.content': `· 카드 변경후에도 환불은 변경전 플랜 결제 카드로 환불 진행됩니다. 
    · 부분 취소 금액 산정 방법 : {(변경전 월 결제 금액) / 30일 } X 남은일수 
    · 결제 금액 산정 방법 : {(변경후 월 결제 금액) / 30일 } X 남은일수 
    · 남은 일수 : 변경일 ~ 다음 정기 결제일 
    · 환불처리는 카드사에 따라 최소 2일~최대 5영업일 소요될 수 있습니다.
    · 결제 24시간 이내 플랜 변경시 기존 플랜 금액 전액 환불 후 
    \u00A0 새로운 플랜이 결제됩니다.`,
    'plan.change-plan.amount.notice':
      '결제 24시간 이내 플랜 변경시 기존 플랜 금액 전액 환불 후 새로운 플랜이 결제됩니다.',
    'plan.change-plan.impossible': '다음 결제일 24시간 미만일 경우에는 플랜을 변경할 수 없습니다.',

    'plan.coupon.notice': '쿠폰 적용시 유의사항 ',
    'plan.coupon.notice.content': `· 플랜 사용중 중도 해지시 쿠폰 혜택은 소멸됩니다.  
    · 쿠폰 중복 적용 불가합니다.
    · 동일한 쿠폰의 경우 1팀당 1회 사용 가능합니다. 
    · 쿠폰 적용된 플랜의 경우 플랜 옵션 변경(최대 인원 변경)이 불가합니다. 옵션 변경필요시 플랜 해지 후 플랜을 재개 해야 하며, 쿠폰 혜택은 소멸됩니다.`,

    // plan processing
    'plan.processing.title': '결제 처리중입니다.',
    'plan.processing.subtitle': '카드 결제가 진행중입니다. 잠시만 기다려주세요',

    // plan completed
    'plan.completed.title.smOnly': '플랜 결제가 완료되었습니다',
    'plan.completed.title.mdAndUp': '플랜 결제가 완료되었습니다',
    'hypler.completed.title': '플코 하이퍼 결제가 완료되었습니다',
    'plan.completed.subtitle.part1.lgAndUp': '플랜 정보 및 결제 내역은',
    'plan.completed.subtitle.part1.mdAndDown': '플랜 정보 및 결제 내역은',
    'plan.completed.subtitle.part2': '\u00A0',
    'plan.completed.subtitle.part3': '에서 확인하실 수 있습니다.',
    'plan.completed.go-home': '홈으로 이동',

    // plan & billing
    'plan.billing.title': '플랜 & 결제',
    'plan.billing.plan-details': '🧢 플랜 정보',
    'plan.billing.players-number': '현재 선수 인원',
    'plan.billing.players-number.can-add': '추가 가능한 선수 인원',
    'plan.billing.plan-expired': '플랜 만료됨',
    'plan.billing.cancel.plan': '플랜 해지',
    'plan.billing.refund.plan': '플랜 환불',
    'plan.billing.change.plan': '플랜 변경',
    'plan.billing.subscribe.plan': '플랜 구독',
    'plan.billing.resume.plan': '플랜 재구독',
    'plan.billing.retry.payment': '결제 재시도',
    'plan.billing.cancel': '해지',
    'plan.billing.refund': '환불',
    'plan.billing.payment.details': '💳 결제 정보',
    'plan.billing.payment.date.expires': '{date} 만료예정',
    'plan.billing.invoice.details': '📩 인보이스 정보',
    'plan.billing.edit': '편집',
    'plan.billing.payment.history': '🧾 결제 내역',
    'plan.billing.license.period': '라이센스 기간',
    'plan.billing.connected.teams': '연결 팀 수',
    'plan.billing.license.name': '라이센스 이름',
    'plan.billing.license.owner': '라이센스 소유자',
    'plan.billing.available.team': '추가 연결 가능한 팀 수',
    'hyper.billing.period': '기간',

    'plan.billing.payment-fail.help.text':
      '결제가 실패했습니다.\n' +
      '결제 재시도는  오전 12시, 오후 12시에 총 4번 진행됩니다. 4번 재시도 동안 결제가 이루어지지 않으면 구독이 만료됩니다. ' +
      '구독 만료전 까지 카드를 확인하시거나 변경해 주세요.',

    // plan & billing status
    'plan.billing.status.refund': '환불',
    'plan.billing.status.pay': '결제 완료',
    'plan.billing.status.fail': '결제 실패',
    'plan.billing.status.loading': '결제 처리 중',
    'plan.billing.status.success': '결제 성공',
    'plan.billing.status.failed': '결제 실패',

    // edit invoice
    'plan.edit.invoice.title': '인보이스 정보 변경',

    // change card
    'plan.change.card.title': '카드를 변경하시겠습니까?',
    'plan.change.card.subtitle.mdAndUp':
      '카드 변경시 유효성 검사를 위해 100원이 결제되며, \n 10분 이내로 환불 됩니다.',
    'plan.change.card.subtitle.smOnly':
      '카드 변경시 유효성 검사를 위해 100원이 결제 \n 되며, 10분 이내로 환불 됩니다.',
    'plan.change.card.button': '카드 변경',
    'plan.change.card.product.name': '카드 유효성 확인',

    // refund plan
    'plan.refund-plan.title': '플랜을 환불하시겠어요?',
    'plan.refund-plan.subtitle': '결제 후 24시간 이내 플랜 환불이 가능합니다.',
    'plan.refund-plan.toasts.success': '플랜이 성공적으로 환불되었습니다.',

    // retry payment
    'plan.retry-payment.title': '결제 재시도 하시겠습니까?',
    'plan.retry-payment.subtitle':
      '카드 변경을 원하시면 결제 정보 > 결제 수단에서 카드 변경 후 다시 시도해 주세요.',
    'plan.retry-payment.toasts.success': '결제가 성공적으로 완료 되었습니다.',
    'plan.retry-payment.toasts.fail': '결제가 실패되었습니다. 결제 수단을 다시 한번 확인해 주세요.',

    // resume plan
    'plan.resume-plan.title': '플랜을 재구독 하시겠습니까?',
    'plan.resume-plan.button': '재구독',
    'plan.resume-plan.toasts.success': '플랜 재구독이 완료 되었습니다.',

    // cancel plan
    'plan.cancel-plan.title': '플랜을 해지하시겠어요?',
    'plan.cancel-plan.subtitle':
      '{day}에 플랜이 해지됩니다. \n 플코를 재구독하시면 저장된 데이터로 \n 모니터링 가능합니다.',
    'plan.cancel-plan.toasts.success': '플랜이 성공적으로 해지되었습니다.',

    // change plan error
    'plan.change.applied-coupon.title': '쿠폰 적용시 플랜 변경이 불가능합니다.',
    'plan.change.applied-coupon.subtitle':
      '플랜 인원 변경을 원하시는 경우 현재 이용중인\n' + '플랜 해지후 다시 시도해 주세요.',

    // plan expiration
    'plan.expiration.title': '플랜이 만료되었습니다',
    'plan.free-trial.expiration.title': '무료체험 기간이 만료되었습니다',
    'plan.expiration.subtitle.smOnly':
      '플랜을 구독하시고 플코의 다양한 서비스들을 이어서 사용해 보세요 😊',
    'plan.expiration.subtitle.mdAndUp':
      '플랜을 구독하시고 플코의 다양한 서비스들을 이어서 사용해 보세요 😊',
  },
  ja: {
    // plan.step
    'plan.step': '{step}/{total}段階', // "{step}/{total} 단계",

    // plan common
    'plan.team.amount': '{amount}ウォン / 月', // "{amount}원 / 월",
    'plan.contact-sales': '問い合わせ要望', // "문의 요망",
    'plan.next': '次', // "다음",
    'plan.contact': '問い合わせ', // "문의하기",
    'plan.make-payment': '決済', // "결제",
    'plan.plan-info': 'プラン情報', // "플랜 정보",
    'plan.plan-info.discount-count': '1~{limitCount}回目', // "1~{limitCount}회차",
    'plan.plan-info.discount-count.first': '1回目',
    'plan.plan-info.original-price-count': '{limitCount}回目より~', // "{limitCount}회차 부터 ~",
    'plan.plan': 'プラン', // "플랜",
    'plan.current.plan': '現在のプラン', // "현재 플랜",
    'plan.new.plan': '新しいプラン', // "새로운 플랜",
    'plan.team': 'チーム', // "팀",
    'plan.elite': 'エリート', // "엘리트",
    'plan.pro': 'プロ', // "프로",
    'plan.payment.method': '決済手段', // "결제 수단",
    'plan.register-card': 'カード登録', // "카드 등록",
    'plan.change-card.info.lgAndUp':
      '· カード登録時、有効性検査のため100ウォンが決済され、10分以内に払い戻しされます.', // "· 카드 등록시 유효성 검사를 위해 100원이 결제되며, 10분 이내로 환불 됩니다.",
    'plan.change-card.info.mdOnly':
      '· カード登録の際、有効性検査のため100ウォンが決済され、\n 10分以内に払い戻しされます.', // "· 카드 등록시 유효성 검사를 위해 100원이 결제되며, \n 10분 이내로 환불 됩니다.",
    'plan.change-card.info.smOnly':
      '· カード登録の際、有効性検査のため100ウォンが\n決済され、10分以内に払い戻しされます.', // "· 카드 등록시 유효성 검사를 위해 100원이 \n 결제되며, 10분 이내로 환불 됩니다.",
    'plan.change-card.error.info': '決済手段を登録してください.', // "결제 수단을 등록해 주세요.",
    'plan.change.card': 'カード変更', // "카드 변경",
    'plan.players-number': '選手人員', // "선수 인원",
    'plan.payment-date': '定期決済日', // "정기결제일",
    'plan.next.payment-date': '次の決済日', // "다음 결제일",
    'plan.invoice.details': 'インボイス情報', // "인보이스 정보",
    'plan.buyer.name': '名前', // "이름",
    'plan.buyer.email': 'メール', // "이메일",
    'plan.buyer.phone': '携帯電話', // "휴대전화",
    'plan.discount.details': '割引情報', // "할인 정보",
    'plan.discount.coupon.null': '適用可能なクーポンがありません.', // "적용 가능한 쿠폰이 없습니다.",
    'plan.discount.coupon.none': 'クーポン適用なし', // "쿠폰 적용 안함",
    'plan.payment.amount': '決済金額', // "결제 금액",
    'plan.current.payment.amount': '現在のプラン金額', // "현재 플랜 금액",
    'plan.new.payment.amount': '新たなプラン金額', // "새로운 플랜 금액",
    'plan.subtotal': 'プラン金額', // "플랜 금액",
    'plan.discount': '割引金額', // "할인 금액",
    'plan.invoice.id': '決済番号', // "결제 번호",
    'plan.payment.date': '決済日', // "결제일",
    'plan.total': '決済金額', // "결제 금액",
    'plan.total.amount': '合計金額', // "총 금액",
    'plan.regular.payment.amount': '定期決済金額', // "정기결제금액",
    'plan.regular.payment.date': '定期決済日', // '정기 결제일',
    'plan.status': '状態', // "상태",
    'plan.view': '見る', // "보기",
    'plan.download': 'ダウンロード', // "다운로드",
    'plan.players': '{players}名', // "{players}명",
    'plan.teams': '{teams}チーム', // "{teams}팀",
    'plan.amount.unit': '{amount}ウォン', // "{amount}원",
    'plan.remaining.days': '残り日数', // "남은 일수",
    'plan.expected.refund.amount': '払戻予定金額', // "환불 예정 금액",
    'plan.day': '{day}日', // "{day}일",
    'plan.coupon': 'クーポン', // "쿠폰",

    // plan error
    'plan.card-error':
      'カードエラーで決済に失敗しました。 カードを変更して支払いをやり直してください。', // "카드 오류로 결제 실패 되었습니다. 카드를 변경하여 결제를 다시 시도해 주세요.",
    'plan.server-error': 'サーバーエラーで決済に失敗しました.お支払いをもう一度お試しください.', // "서버 에러로 결제 실패 되었습니다. 결제를 다시 시도해 주세요.",
    'plan.incomplete-error': 'カードの登録が完了していません.もう一度お試しください.', // "카드 등록이 완료되지 않았습니다. 다시 시도해주세요.",

    // plan
    price__section1__title: 'プランを選択してください。',
    price__section1__description:
      'チームの特性と環境に必要なカスタマイズされたプランを選択できます。',

    price__card__contact__title: 'プランのお問い合わせ',
    price__card__contact__description: 'オーダーメイドプランの相談\n(協会、連盟、教育庁など)',

    price__card__toggle__annual: '年間購読20%割引',

    price__card__lite__title: 'Lite',
    price__card__lite__description: '必須基本業務を\nリーズナブルな価格で',
    price__card__lite__payment_month: '₩55,000 / 月',
    price__card__lite__payment_month_og: '₩495,000',
    price__card__lite__payment__year: '₩528,000 / 年',
    price__card__lite__payment__year__og__1: '₩5,940,000',
    price__card__lite__payment__year__og__2: '₩660,000',
    price__card__lite__feature_1: 'チームスケジュール管理',
    price__card__lite__feature_2: '訓練/試合レポート管理',
    price__card__lite__feature_3: 'チームデータモニタリング',
    price__card__lite__feature_4: '選手データモニタリング',
    price__card__lite__feature_5: 'コミュニケーション',

    price__card__elite__title: 'Elite',
    price__card__elite__description: '自動化サービスまで加え、\n最大化する利便性',
    price__card__elite__payment__month: '₩77,000 / 月',
    price__card__elite__payment__month__og: '₩550,000',
    price__card__elite__payment__year: '₩739,200 / 年',
    price__card__elite__payment__year__og__1: '₩6,600,000',
    price__card__elite__payment__year__og__2: '₩924,000',
    price__card__elite__feature: 'ライトプランのすべての機能、サービスが含まれます。',
    price__card__elite__feature_1: 'チェック通知送信自動化サービス',
    price__card__elite__feature_2: '日刊/週間レポートカカオトークサービス',
    price__card__elite__feature_3: 'チェック選手リストカカオトークサービス',

    price__card__pro__title: 'Pro',
    price__card__pro__label: 'for Professional',
    price__card__pro__description: 'すべての機能に合わせた\n専門機能まで',
    price__card__pro__payment__month: '₩550,000 / 月',
    price__card__pro__payment__month__og: '₩1,100,000',
    price__card__pro__payment__year: '₩5,280,000 / 年',
    price__card__pro__payment__year__og__1: '₩13,200,000',
    price__card__pro__payment__year__og__2: '₩6,600,000',
    price__card__pro__feature:
      'ライト、エリート 料金プラン のすべての機能とサービスが含まれています。',
    price__card__pro__feature_1: 'データ原本抽出サービス(月1回)',
    price__card__pro__feature_2: 'データレポートサービス(月1回)',

    price__card__promotion__banner: '学生·アマチュアチーム専用',
    price__card__pro__banner: 'プロチーム専用',
    price__card__promotion_notice:
      '学生またはアマチュアのための\nプロモーションプランで、\n証明をお願いすることもできます。',
    price__card__select_button: '選択',

    'plan.elite.description': '小中高校や大学など\n学生チームのためのプラン', // '초, 중, 고, 대학 등\n학생팀을 위한 플랜',
    'plan.pro.description': 'プロ、国家代表など\nプロレベル向けのプラン', // '프로, 국가대표, 실업팀 등\n프로 레벨을 위한 플랜',

    'plan.pay.contect-description':
      '月の定期支払い以外で、チーム数、利用期間、支払い方法などをご相談いただく場合はお問い合わせください。 (例: 協会、連盟、教育委員会など)',
    'plan.pay.contact-link':
      'https://docs.google.com/forms/d/e/1FAIpQLSdm36MHvdMfIyPgB5o8dByLJpfEDKb86IrKdS5UJJLPuNdh6A/viewform',

    // plan plan-option
    'plan.option.title': '選手人数を選択してください', // "선수 인원을 선택해 주세요",
    'plan.option.players-number': '選手人数{total}名', // "선수 인원 {total}명",
    'plan.option.subtitle.smOnly':
      '現在のチーム内の{players}です. 所有者と管理者権限は\n別途の人数制限なしに追加可能です.', // "현재 팀 내 {players}입니다. 소유자와 관리자 권한은 \n 별도의 인원 제한 없이 추가 가능합니다.",
    'plan.option.subtitle.mdAndUp':
      '現在のチーム内の{players}です. \n所有者と管理者権限は、別途の人数制限なしに追加可能です.', // "현재 팀 내 {players}입니다. \n 소유자와 관리자 권한은 별도의 인원 제한 없이 추가 가능합니다.",
    'plan.option.info-part1': 'もっと低いプランをお望みでしたら', // "더 낮은 플랜을 원하시면",
    'plan.option.info-part2.lgAndUp': 'に移動して選手人数を変更した後、選択してください。', // "으로 이동하여 선수 인원 변경후 선택해주세요.",
    'plan.option.info-part2.mdAndDown': 'に移動して', // "으로 이동하여",
    'plan.option.info-part3.mdAndDown': '選手の人数を変更した後に選択してください。', // "선수 인원 변경후 선택해주세요.",
    'plan.option.10': '1~10人', // "1~10인",
    'plan.option.20': '11~20人', // "11~20인",
    'plan.option.40': '21~40人', // "21~40인",
    'plan.option.50': '41~50人', // "41~50인",
    'plan.option.100': '51~100人', // "51~100인",
    'plan.option.100+': '101人以上', // "101인이상",
    'plan.option.disabled-card-info': 'チーム内の選手人数を超えているため、選択できません.', // "팀 내 선수 인원이 초과되어 선택할 수 없습니다.",
    'plan.option.current.plan': '現在利用中のプラン', // "현재 이용중인 플랜",
    'plan.option.select.plan.error': 'ご希望のプランをお選びください.', // "원하시는 플랜을 선택하세요.",

    // plan pricing
    'plan.pricing.title.smOnly': 'プラン及び決済金額をご確認ください.', // "플랜 및 결제 금액을 확인해 주세요",
    'plan.pricing.title.mdAndUp': 'プラン及び決済金額をご確認ください.', // "플랜 및 결제 금액을 확인해 주세요",
    'plan.pricing.subtitle.smOnly': '決済情報を確認した後、決済が行われます.', // "결제 정보 확인후 결제가 진행됩니다.",
    'plan.pricing.subtitle.mdAndUp': '決済情報を確認した後、決済が行われます.', // "결제 정보 확인후 결제가 진행됩니다.",
    'plan.pricing.payment-day': '毎月{day}日', // "매월 {day}일",
    'plan.pricing.total': '最終金額', // "최종 금액",
    'plan.pricing.amount.info.smOnly':
      '有料サービスの利用を開始してからは\n契約の申込みの撤回ができません.', // "유료서비스의 이용을 개시한 이후로는 \n 청약철회가 불가합니다.",
    'plan.pricing.amount.info.mdAndUp':
      '有料サービスの利用を開始してからは契約の申込みの撤回ができません。', // "유료서비스의 이용을 개시한 이후로는 청약철회가 불가합니다.",
    'plan.pricing.terms': '決済情報を保存し、月々の定期決済に同意します.', // "결제 정보를 저장하고 월 정기 결제를 동의합니다.",
    'plan.pricing.current-plan.info': '現在のプランと同じプランは選択できません.', // "현재 플랜과 동일한 플랜은 선택할 수 없습니다.",
    'plan.pricing.plan-info.notice': '{day}から変更された金額で定期決済が行われます.', // "{day} 부터 변경된 금액으로 정기결제가 진행됩니다.",
    'plan.change-plan.notice': 'プラン変更注意事項', // "플랜 변경 유의사항",
    'plan.change-plan.notice.content':
      '· カード変更後も払い戻しは変更前にプラン決済カードで行われます。' + // `· 카드 변경후에도 환불은 변경전 플랜 결제 카드로 환불 진행됩니다.
      '· 部分キャンセル金額の算定方法:{(変更前の月額決済金額)/ 30日}X残り日数' + // · 부분 취소 금액 산정 방법 : {(변경전 월 결제 금액) / 30일 } X 남은일수
      '· 決済金額の算定方法:{(変更後の月額決済金額)/ 30日}X残り日数' + // · 결제 금액 산정 방법 : {(변경후 월 결제 금액) / 30일 } X 남은일수
      '· 残り日数:変更日~翌定期決済日' + // · 남은 일수 : 변경일 ~ 다음 정기 결제일
      '· 払い戻し処理はカード会社によって最低2日~最大5営業日かかる場合があります。' + // · 환불처리는 카드사에 따라 최소 2일~최대 5영업일 소요될 수 있습니다.
      '· 決済24時間以内にプランを変更する場合、既存プランの金額を全額返金した後' + // · 결제 24시간 이내 플랜 변경시 기존 플랜 금액 전액 환불 후
      '\u00A0 新しいプランが決済されます。', // \u00A0 새로운 플랜이 결제됩니다.`,
    'plan.change-plan.amount.notice':
      '決済24時間以内にプランを変更すると、既存のプラン金額を全額返金した後、新しいプランが決済されます.', // "결제 24시간 이내 플랜 변경시 기존 플랜 금액 전액 환불 후 새로운 플랜이 결제됩니다.",
    'plan.change-plan.impossible': '次の支払い日が24時間未満の場合は、プランを変更できません。',
    'plan.coupon.notice': 'クーポン適用時の注意事項', // "쿠폰 적용시 유의사항 ",
    'plan.coupon.notice.content':
      'プランを使用中に中途解約した場合、クーポン特典は失効します。\n' + // `· 플랜 사용중 중도 해지시 쿠폰 혜택은 소멸됩니다.
      '· クーポンの重複適用はできません。\n' + // · 쿠폰 중복 적용 불가합니다.
      '· 同じクーポンの場合、1チーム当たり1回使用できます。\n' + // · 동일한 쿠폰의 경우 1팀당 1회 사용 가능합니다.
      '· クーポンが適用されたプランの場合、プランオプションの変更(最大人数変更)はできません。 オプション変更が必要な場合は、プラン解約後にプランを再開する必要があり、クーポン特典は消滅します。', // · 쿠폰 적용된 플랜의 경우 플랜 옵션 변경(최대 인원 변경)이 불가합니다. 옵션 변경필요시 플랜 해지 후 플랜을 재개 해야 하며, 쿠폰 혜택은 소멸됩니다.`,

    // plan processing
    'plan.processing.title': '決済処理中です.', // "결제 처리중입니다.",
    'plan.processing.subtitle': 'カード決済が進行中です。少々お待ちください', // "카드 결제가 진행중입니다. 잠시만 기다려주세요",

    // plan completed
    'plan.completed.title.smOnly': 'プラン決済が完了しました', // "플랜 결제가 완료되었습니다",
    'plan.completed.title.mdAndUp': 'プラン決済が完了しました', // "플랜 결제가 완료되었습니다",
    'plan.completed.subtitle.part1.lgAndUp': 'プラン情報及び決済内訳は', // "플랜 정보 및 결제 내역은",
    'plan.completed.subtitle.part1.mdAndDown': 'プラン情報及び決済内訳は', // "플랜 정보 및 결제 내역은",
    'plan.completed.subtitle.part2': '\u00A0',
    'plan.completed.subtitle.part3': 'で ご確認いただけます.', // "에서 확인하실 수 있습니다.",
    'plan.completed.go-home': 'ホームへ移動', // "홈으로 이동",

    // plan & billing
    'plan.billing.title': 'プラン & 決済', // "플랜 & 결제",
    'plan.billing.plan-details': '🧢 プラン情報', // "🧢 플랜 정보",
    'plan.billing.players-number': '現在の選手人数', // "현재 선수 인원",
    'plan.billing.players-number.can-add': '追加可能な選手人数', // "추가 가능한 선수 인원",
    'plan.billing.plan-expired': 'プラン切れ', // "플랜 만료됨",
    'plan.billing.cancel.plan': 'プラン解約', // "플랜 해지",
    'plan.billing.refund.plan': 'プラン返金', // "플랜 환불",
    'plan.billing.change.plan': 'プラン変更', // "플랜 변경",
    'plan.billing.subscribe.plan': 'プラン購読', // "플랜 구독",
    'plan.billing.resume.plan': 'プランの再購読', // "플랜 재구독",
    'plan.billing.retry.payment': '決済の再試行', // "결제 재시도",
    'plan.billing.cancel': '解約', // "해지",
    'plan.billing.refund': '払戻', // "환불",
    'plan.billing.payment.details': '💳決済情報', // "💳 결제 정보",
    'plan.billing.payment.date.expires': '{date}満了予定', // "{date} 만료예정",
    'plan.billing.invoice.details': '📩 インボイス情報', // "📩 인보이스 정보",
    'plan.billing.edit': '編集', // "편집",
    'plan.billing.payment.history': '🧾 決済内訳', // "🧾 결제 내역",
    'plan.billing.license.period': 'ライセンス期間', // "라이센스 기간",
    'plan.billing.connected.teams': '連結チーム数', // "연결 팀 수",
    'plan.billing.license.name': 'ライセンス名', // "라이센스 이름",
    'plan.billing.license.owner': 'ライセンス所有者', // "라이센스 소유자",
    'plan.billing.available.team': '追加接続可能なチーム数', // "추가 연결 가능한 팀 수",

    // TODO: check
    'plan.billing.payment-fail.help.text':
      'お支払いに失敗しました。\n' +
      '「決済の再試行は午前12時、午後12時に合計4回進行されます。' +
      '「登録の有効期限前までにカードを確認または変更してください。',

    // plan & billing status
    'plan.billing.status.refund': '払戻', // "환불",
    'plan.billing.status.pay': '決済完了', // "결제 완료",
    'plan.billing.status.fail': '決済失敗', // "결제 실패",
    'plan.billing.status.loading': '決済処理中', // '결제 처리 중',
    'plan.billing.status.success': '決済成功', // "결제 성공",
    'plan.billing.status.failed': '決済失敗', // "결제 실패",

    // edit invoice
    'plan.edit.invoice.title': 'インボイス情報の変更', // "인보이스 정보 변경",

    // change card
    'plan.change.card.title': 'カードを変更しますか？', // "카드를 변경하시겠습니까?",
    'plan.change.card.subtitle.mdAndUp':
      'カード変更の場合、有効性検査のため100ウォンが決済され、\n 10分以内に払い戻しされます.', // "카드 변경시 유효성 검사를 위해 100원이 결제되며, \n 10분 이내로 환불 됩니다.",
    'plan.change.card.subtitle.smOnly':
      'カード変更の際、有効性検査のため100ウォンが決済\nされ、10分以内に払い戻しされます.', // "카드 변경시 유효성 검사를 위해 100원이 결제 \n 되며, 10분 이내로 환불 됩니다.",
    'plan.change.card.button': 'カード変更', // "카드 변경",
    'plan.change.card.product.name': 'カードの有効性確認', // "카드 유효성 확인",

    // refund plan
    'plan.refund-plan.title': 'ランを払い戻しますか？', // "플랜을 환불하시겠어요?",
    'plan.refund-plan.subtitle': 'お支払い前の24時間以内にプランの払い戻しが可能です.', // "결제 전 24시간 이내 플랜 환불이 가능합니다.",
    'plan.refund-plan.toasts.success': 'プランが正常に返金されました。', // "플랜이 성공적으로 환불되었습니다.",

    // retry payment
    'plan.retry-payment.title': 'お支払いを再試行してもよろしいですか。', // "결제 재시도 하시겠습니까?"
    'plan.retry-payment.subtitle':
      'カードを変更したい場合は、お支払い情報 > お支払い方法でカードを変更してもう一度お試しください。', // 카드 변경을 원하시면 결제 정보 > 결제 수단에서 카드 변경 후 다시 시도해 주세요.
    'plan.retry-payment.toasts.success': 'お支払いが正常に完了しました。', // 결제가 성공적으로 완료 되었습니다.
    'plan.retry-payment.toasts.fail':
      'お支払いに失敗しました。お支払い方法をもう一度確認してください。', // 결제가 실패되었습니다. 결제 수단을 다시 한번 확인해 주세요.

    // resume plan
    'plan.resume-plan.title': 'プランを再購読しますか？', // "플랜을 재구독 하시겠습니까?",
    'plan.resume-plan.button': '再購読', // "재구독",
    'plan.resume-plan.toasts.success': 'プランの再購読が完了しました.', // "플랜 재구독이 완료 되었습니다.",

    // cancel plan
    'plan.cancel-plan.title': 'プランを解約しますか。', // "플랜을 해지하시겠어요?",
    'plan.cancel-plan.subtitle':
      '{day}にプランが解約されます. \nプルコを再購読すると、保存されたデータで\nモニタリングが可能で.', // "{day}에 플랜이 해지됩니다. \n 플코를 재구독하시면 저장된 데이터로 \n 모니터링 가능합니다.",
    'plan.cancel-plan.toasts.success': 'プランが正常に解約されました.', // "플랜이 성공적으로 해지되었습니다.",

    // change plan error
    'plan.change.applied-coupon.title': 'クーポンを適用した場合、プランの変更はできません.', // "쿠폰 적용시 플랜 변경이 불가능합니다.",
    'plan.change.applied-coupon.subtitle':
      'プラン人数変更をご希望の場合 現在ご利用中の\n' + // "플랜 인원 변경을 원하시는 경우 현재 이용중인\n" +
      'プランを解約した後、もう一度お試しください.', // "플랜 해지후 다시 시도해 주세요.",

    // plan expiration
    'plan.expiration.title': 'プランが満了しました', // "플랜이 만료되었습니다",
    'plan.free-trial.expiration.title': '無料体験期間が満了しました.', // "무료체험 기간이 만료되었습니다",
    'plan.expiration.subtitle.smOnly':
      'プランを購読し、プルコのさまざまなサービスを継続してお試しください. 😊', // "플랜을 구독하시고 플코의 다양한 서비스들을 이어서 사용해 보세요 😊",
    'plan.expiration.subtitle.mdAndUp':
      'プランを購読し、プルコのさまざまなサービスを継続してお試しください. 😊', // "플랜을 구독하시고 플코의 다양한 서비스들을 이어서 사용해 보세요 😊",

    // plan promotion @주빈님
    'plan.promotion': '프로모션',
    'plan.promotion.plan-option.notice.part1': '웰컴 프로모션 쿠폰 적용된',
    'plan.promotion.plan-option.notice.part2': '3개월 특가 50%',
    'plan.promotion.plan-option.notice.part3.lgAndUp':
      '가격이며 넷째달 부터 정상 가격으로 결제됩니다.',
    'plan.promotion.plan-option.notice.part3.mdOnly':
      '가격이며 넷째달 부터 정상 가격으로 결제됩니다.',
    'plan.promotion.plan-option.notice.part3.smOnly': '가격이며',
    'plan.promotion.plan-option.notice.part4.mdOnly': '\u00A0',
    'plan.promotion.plan-option.notice.part4.smOnly': '넷째달 부터 정상 가격으로 결제됩니다.',
  },
  vn: {
    'plan.pay.contact-link':
      'https://docs.google.com/forms/d/e/1FAIpQLSc3OlUZgM8YRseHHo47kQ8VNbMFJvt38fFYDJGaEUUq5QB4_A/viewform?usp=sf_link',
  },
}
