import { Dispatch, ReactNode, SetStateAction, createContext, useEffect, useState } from 'react'

import { __ENV__ } from '@plco-pro/env'
import ChannelTalk from '@plco-pro/utils/libs/channel-talk'
import { IChannelTalk } from '@plco-pro/utils/libs/channel-talk.types'

interface ReactChannelIOContextValue {
  isInitialized: boolean
  setIsInitialized: Dispatch<SetStateAction<boolean>>
  channelTalk: IChannelTalk | undefined
}

export const ReactChannelIOContext = createContext(null as unknown as ReactChannelIOContextValue)

const ChannelTalkProvider = ({ children }: { children?: ReactNode }) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const [channelTalk, setChannelTalk] = useState<IChannelTalk>()

  useEffect(() => {
    const dynamicallyImportPackage = () => {
      if (isInitialized) {
        return
      }

      if (__ENV__ === 'development') {
        return
      }

      setChannelTalk(new ChannelTalk())
    }

    dynamicallyImportPackage()
  }, [isInitialized])

  useEffect(() => {
    const settings = {
      zIndex: 1000,
    }

    channelTalk?.boot(
      {
        /**
         * 기본 채널톡 버튼을 보이지 않게
         * https://developers.channel.io/reference/web-customization-kr
         */
        hideChannelButtonOnBoot: true,
        ...settings,
      },
      () => {
        setIsInitialized(true)
      },
    )
  }, [channelTalk, setIsInitialized])

  return (
    <ReactChannelIOContext.Provider value={{ isInitialized, setIsInitialized, channelTalk }}>
      {children}
    </ReactChannelIOContext.Provider>
  )
}

export default ChannelTalkProvider
